const {fr} = require("date-fns/locale");
const { registerLocale, setDefaultLocale } = require("react-datepicker");

registerLocale('fr', fr);
setDefaultLocale('fr');

module.exports = {
  "api_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MjI3MDA0NzQsImV4cCI6MTY1NDIzNjQ3NCwidXNlcm5hbWUiOiIzOSJ9.zwtl76to23UQCkU0YZ2_nXhraXHiTecT01Jm2jCqEi8J6DDWtuU7ZSKIsHcmLkeBq7vp5EDQZgnemlEV9hwp-lLZ6bsTeqju0PmZO2c0I3DxDAKtqr5xHMtDW1qwqXTo6rs0CiAyFpYNkIoWQOK2Um7B19Vf4x5wMHhA76FOLcRTX1KmiJtShv6jekXdGqzusQjxFBgEacZJcHrbA58i0l-s-uyPxNV6i9wm9NWwHiVcziZdeqspNs1gGEkJYpfyqFYsnmUf7T31gGtK0RtOyjvAoPCuWVCMdNi-jgHdbTkMNTJ6IM2flKhR0LcBk-4Vqr9rEJcA8rHOxIPmPffVVy0XBAoLE3Zw5u9jgpv4saONqCVubw1y6Ix9DKfa_gNTbYcrVD9ZGcQph882nNY5hBgAuHt7PuJgNhKGVHrxs2_hHs3KzxW2ZxlpbgB56MfvV85V8qTzHfckAOuV2HKzUGze2bdcaVrLVOJAgziZRymqpkSfSLmc0rCsiAPUb5JVr66GguTiZefoj4A-riya71-HaCovKaZOO6gFT_vJy84LC1qI1M7T64pb-CcPymQDsrH_SvOVFdZNhPzsbDkU8EaPw6J5csO4_OjlGpINOlYkdA5BWQUBgEFBXkZcIGMv9Zmcp-xTewP4j1RGRnY7YeeIoyNw-uv3HfWF6T5fzE0",
  "date_format": {
    "en": "MM/dd/yyyy",
    "fr": "dd/MM/yyyy"
  },
  "display_contact": true,
  "fb_display": false,
  "info_traffic_title": {
    "en": "All the traffic info",
    "fr": "Toute l'info trafic"
  },
  "last_news_label": {
    "en": "News",
    "fr": "Actualités"
  },
  "mapbox_token": "pk.eyJ1IjoibWltaXJvc2UyOSIsImEiOiJjbGV3cHhsaGgxMHV2NDNtazAxOGM0MXhrIn0.LieDdLfJevyJ6njBc9-vQg",
  "notifications_service_endpoint": "https://apim.keolis.com/KWP000/notification",
  "search_title": {
    "en": "Search",
    "fr": "Recherche"
  },
  "side_blocks": {
    "en": [
      "66155046",
      "66162729"
    ],
    "fr": [
      "47180785",
      "50260371"
    ]
  },
  "api_url": "https://api-v2.mobireport.fr",
  "contact_label": {
    "en": "Information",
    "fr": "Renseignements"
  },
  "info_traffic_slug": {
    "en": "all-the traffic-info",
    "fr": "info-trafic"
  },
  "news_redirect_label": {
    "en": "Read the news",
    "fr": "Lire l'actualité"
  },
  "notifications_service_import_file_endpoint": "https://apim.keolis.com/KWP000/file/import",
  "search_button_label": {
    "en": "Search",
    "fr": "Rechercher"
  },
  "serverless_sendmail_endpoint": "https://apim.keolis.com/dato/send/form",
  "time_format": {
    "en": "HH:mm",
    "fr": "HH:mm"
  },
  "twitter_display": false,
  "website_name": "Capbus",
  "contact_phone_number": {
    "en": "04 67 01 22 24",
    "fr": "04 67 01 22 24"
  },
  "info_traffic_detail_slug": {
    "en": "Detail of the traffic information",
    "fr": "info trafic"
  },
  "no_search_label": {
    "en": "Please enter terms and validate to launch the search",
    "fr": "Veuillez saisir des termes et valider pour lancer la recherche"
  },
  "notifications_service_token": "1142321004f3407b98a95856f9820607",
  "primary_color": {
    "red": 0,
    "blue": 158,
    "alpha": 255,
    "green": 83
  },
  "serverless_sendmail_token": "1142321004f3407b98a95856f9820607",
  "alt_text": {
    "en": "Contact : 04 67 01 22 24",
    "fr": "Renseignements : 04 67 01 22 24"
  },
  "gradient_primary_color": {
    "red": 2,
    "blue": 255,
    "alpha": 255,
    "green": 135
  },
  "info_traffic_content_title": {
    "en": "Traffic information",
    "fr": "L'information trafic"
  },
  "search_in_progress_label": {
    "en": "Research in progress...",
    "fr": "Recherche en cours..."
  },
  "gradient_button_color": {
    "red": 16,
    "blue": 153,
    "alpha": 255,
    "green": 158
  },
  "redirection_button_text": {
    "en": "MORE INFOS",
    "fr": "+ D'INFOS"
  },
  "search_no_results_label": {
    "en": "There are no results for this search",
    "fr": "Il n'y a aucun résultat pour cette recherche"
  },
  "active_button_text_color": {
    "red": 255,
    "blue": 255,
    "alpha": 255,
    "green": 255
  },
  "search_error_label": {
    "en": "An error has occurred, please try again in a few minutes",
    "fr": "Une erreur s'est produite, veuillez réessayer dans quelques minutes"
  },
  "search_token": "8d0a878279304985cc55d0d41bdfc3",
  "secondary_color": {
    "red": 255,
    "blue": 255,
    "alpha": 255,
    "green": 255
  },
  "subsidiary_logo": {
    "path": "/77327/1693412804-logo-cap-bus-blanc.png",
    "format": "png",
    "size": 19320,
    "alt": "Logo Capbus",
    "title": "Capbus",
    "width": 1020,
    "height": 305
  },
  "back_to_home_title": {
    "en": "Keolis Web Passenger – Back to Homepage",
    "fr": "Keolis Web Passenger – revenir à la page d'accueil"
  },
  "language_selector_title": {
    "en": "Change the language",
    "fr": "Changer la langue"
  },
  "piano_site_id": "639207",
  "google_tag_manager_id": "GTM-T3GN65T",
  "google_analytics_key": "",
  "didomi_api_key": "2127e597-3d3f-4aa2-9727-c8126f86298c",
  "actistat_key": "",
  "loader": {
    "path": "/77327/1693507395-loader-capbus.gif",
    "format": "gif",
    "size": 737623,
    "alt": "Page en chargement, veuillez patientez s'il vous plaît",
    "title": "Page en chargement",
    "width": 1080,
    "height": 1080
  },
  "api_url_get_lines_endpoint": "/public/line",
  "api_url_user_registration_endpoint": "/public/customer",
  "scripts": [
    "47180903"
  ],
  "form_captcha_key": "6Lcd1DMjAAAAAEsHVSyCqBSA_c8IBpjJStjgY_pH",
  "updated_at": "2025-02-07T11:01:13.016+01:00",
  "created_at": "2020-12-29T15:46:21.809+01:00",
  "locale": "fr",
  "locales": [
    "fr",
    "en"
  ],
  "siteUrl": "https://www.capbus.fr/",
  "readOnlyApi": "81f3ed68109a1ba8aea3bd0be3de17"
}